.product-list {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-bottom: 28%;
}

.product-list {
    padding: 20px;
  }
  .product-list {
    padding: 20px;
  }
  
  .filter-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .filter-buttons button {
    background-color: #5a9fe4; /* Blu marino scuro per il bottone attivo */
    border: none;
    color: white;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0; /* Margine ridotto per avvicinare i bottoni */
    cursor: pointer;
    border: 1px solid #000;  
    border-radius: 6px;
    transition: background-color 0.3s ease;
  }
  
  .filter-buttons button:hover {
    background-color: #1e7ed7; /* Blu marino più chiaro per hover */
  }
  
  .filter-buttons button:active {
    background-color: #142c70; /* Blu marino scuro per active */
  }
  
  .filter-buttons button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(30, 58, 138, 0.5); /* Luce blu marino intorno al bottone quando ha il focus */
  }

  .filter-buttons button:active {
    background-color: #0d30cc; /* Blu marino scuro per active */
  }
  
  .filter-buttons button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(30, 58, 138, 0.5); /* Luce blu marino intorno al bottone quando ha il focus */
  }
  
  .filter-buttons button.active {
    background-color: #0d45c8; /* Blu marino */
  }


  .subcategory-label {
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF; /* Colore del testo bianco */
    margin: 20px 0 10px; /* Margine sopra e sotto per separare dalle altre sezioni */
    padding: 10px;
    border-left: 5px solid #2aa2ca; /* Bordo sinistro colore acqua */
    background-color: #1abcf1; /* Sfondo colore acqua */
    border-radius: 5px;
  }

  .subcategory-label:hover {
    background-color: #8de2fe;
}

.subcategory-label:active {
    background-color: #0f82a8;
    transform: scale(0.98); /* Effetto di pressione */
}

.subcategory-label.active {
    background-color:#0f82a8; /* Colore speciale per la categoria attiva */
    color: #0056b3; /* Cambia colore del testo per l'elemento attivo */
    border-left: 5px solid #0056b3; /* Aggiunge un effetto visivo di selezione */
}

.product-list-container {
  display: flex;
  overflow-x: auto; /* Abilita lo scorrimento orizzontale */
  white-space: nowrap; /* Impedisce che gli elementi vadano su più righe */
  padding: 10px;
}