.App-header {
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    border: 1px solid #000;  
    padding: 20px; 
    margin-bottom: 5px;  
    background-color: #024282;  
}

.info-button button {
    background-color: #1c82e7; /* Blu marino scuro per il bottone attivo */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 2px 2px; /* Margine ridotto per avvicinare i bottoni */
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }