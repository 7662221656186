/* Ordina la tabella */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

/* Stile delle celle dell'intestazione */
th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
    padding: 8px;
    text-align: center;
    border-bottom: 2px solid #ddd;
}

/* Stile delle celle del corpo */
td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

/* Stile delle righe pari */
tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Stile delle righe quando passi sopra con il mouse */
tr:hover {
    background-color: #f2f2f2;
}