.App-footer {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-around;
    /* Modificato da space-between per una migliore spaziatura */
    align-items: center;
    bottom: 0;
    background-color: #00bcd4;
    /* Azzurro Tiffany */
    color: white;
    height: 18%;
}

.order-cart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.costs-container {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 10px;
    justify-content: start;
    align-items: center;
    /* Se necessario, aggiungi un padding o una larghezza minima */
    min-width: 200px;
    /* Imposta una larghezza minima per evitare il sovraffollamento */
}

.cost-item {
    padding: 5px 10px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    font-size: 0.9rem;
    margin-bottom: 5px;
}

.order-button-container {
    /* Aggiungi una larghezza minima o un padding per assicurare la visibilità */
    min-width: 150px;
    /* O un valore che funzioni meglio per il design */
}

.order-button {
    padding: 10px 20px;
    background-color: white;
    margin-top: 10px;
    color: #0abab5;
    /* Colore del testo Azzurro Tiffany per contrastare con il bianco */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Aggiungi una larghezza per assicurare la visibilità */
    /* Aggiungi overflow per gestire il testo troppo lungo */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order-button:hover {
    background-color: #e0f2f1;
    /* Colore chiaro per l'hover */
}


.cart-container {
    position: relative;
}

.cart-circle {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    background-color: green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-count {
    color: white;
    font-size: 0.8rem;
}

.cart-icon {
    width: 30px;
    height: auto;
}
/* Bottone carrello */
.cart-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

/* Cerchio di sfondo per il carrello */
.cart-background {
    width: 40px;  /* Dimensione del cerchio */
    height: 40px;
    background-color: #f0f0f0;  /* Colore iniziale */
    border-radius: 50%;  /* Crea un cerchio perfetto */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;  /* Transizione per cambio colore */
}

/* Cerchio cambia colore quando si passa sopra con il mouse */
.cart-button .cart-background {
    background-color: #e0e0e0;  /* Colore più scuro all'hover */
}