.App {
  text-align: center;
}

body {
  color: #333;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  /*background-color: #e6a1a1;*/
}

.cart-section {
  max-height: 70vh; /* Imposta l'altezza massima desiderata */
  overflow-y: auto; /* Abilita lo scroll verticale */
  padding-bottom: 100px; /* Margine per il footer */
}