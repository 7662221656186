* {
    box-sizing: border-box;
}

form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 10px;
}

label {
    display: block;
    margin-bottom: 10px;
}


/* Style the input container */
.input-container {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    flex-direction: column;
    align-items: center;
}



.input-row {
    display: flex;
    align-items: center;
    align-content: center;
}

/* Style the form icons */
.icon {
    padding: 10px;
    background: dodgerblue;
    color: white;
    min-width: 50px;
    text-align: center;
    margin-bottom: 10px;
}

/* Style the input fields */
.input-field {
    width: 100%;
    padding: 10px;
    outline: none;
    margin-bottom: 10px;
}

.input-field:focus {
    border: 2px solid dodgerblue;
}

/* Set a style for the submit button */
.btn {
    background-color: dodgerblue;
    color: white;
    padding: 15px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
}

.btn:hover {
    opacity: 1;
}